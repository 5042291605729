/* Sidebar container */
.ts-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  left: 0;
  top: 100px; /* Adjust based on your navbar height */
  bottom: 0;
  width: 150px; /* Fixed width for clarity */
  background-color: #2b3e50; /* Subtle dark theme */
  color: #fff;
  padding: 20px 10px; /* Uniform padding */
  font-size: 1rem; /* Standard font size */
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.15); /* Shadow for depth */
  z-index: 1000; /* Ensure it's behind the navbar */
  transition: width 0.3s ease-in-out; /* Smooth transition */
}

/* Sidebar navigation container */
.ts-flex-column {
  margin-top: 20px; /* Moves content inside the sidebar down */
  padding: 0 10px;
}

/* Individual navigation link container */
.ts-nav-link-container {
  margin-bottom: 8px; /* Uniform spacing between items */
}

/* Navigation links */
.ts-nav-link {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
  color: #d0d6dc;
  padding: 10px 12px; /* Balanced padding */
  border-radius: 5px;
  text-decoration: none; /* Remove underline */
  transition: background 0.3s ease, color 0.3s ease;
  border-left: 3px solid transparent; /* Default border */
  box-sizing: border-box;
  font-size: 1rem;
}

/* First item (Home) gets extra spacing */
.ts-nav-link:first-child {
  margin-top: 20px;
}

/* Hover Effect */
.ts-nav-link:hover {
  background-color: #4b5d6c;
  color: #ffffff;
  border-left: 3px solid #3498db;
}

/* Active State */
.ts-nav-link.ts-active {
  color: #ffffff;
  background-color: #1c2733;
  font-weight: 600;
  border-left: 3px solid #3498db;
}

/* Sidebar transition effect */
.ts-sidebar {
  transition: all 0.3s ease-in-out;
}

/* Icons in links */
.ts-nav-link svg {
  font-size: 18px; /* Adjust icon size */
}

/* Special chatbot link styling */
.ts-chatbot-link {
  display: flex;
  align-items: center;
}

.ts-chatbot-link svg {
  margin-right: 8px; /* Space between icon and text */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .ts-sidebar {
      width: 20%; /* Adjust sidebar width */
      font-size: 12px;
  }

  .ts-nav-link svg {
      font-size: 16px;
  }
}

/* Spacing for last item */
.ts-nav-link-container:last-child {
  margin-bottom: 0;
}