.jt-inbox-container {
    padding: 24px;
    background-color: #ffffff;
    border-radius: 12px;
    max-width: 800px;
    margin: 20px auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .inbox-header {
    color: #1976d2; /* Material-UI primary color */
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .loading-text {
    margin-top: 10px;
    color: #1976d2;
  }
  
  .error-container,
  .empty-container {
    margin-top: 20px;
    text-align: center;
  }
  
  .message-item {
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .message-item:hover {
    background-color: #f5f5f5;
    transform: translateX(5px);
  }
  
  .message-item:active {
    background-color: #e0e0e0;
  }
  
  .icon-button {
    color: #1976d2;
  }
  
  .timestamp {
    display: block;
    margin-top: 4px;
    color: #757575; /* light gray for timestamp */
    font-size: 0.875rem; /* smaller font size for timestamp */
  }