/* CrossTab container styling */
.cross-tab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #2b3e50; /* Match sidebar theme */
  color: #d0d6dc; /* Light text color */
  padding: 50px; /* Add consistent padding */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  margin: 20px auto; /* Center container */
  width: 90%; /* Constrain width */
}
.container-fluid {
  margin-left: 15px; /* Moves the entire container 30px to the right */
}

/* Table styling */
.cross-tab-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #1c2733; /* Dark background for table */
  color: #d0d6dc; /* Light text color */
  font-size: 10px; /* Readable font size */
  border-radius: 5px; /* Rounded corners for the table */
  overflow: hidden; /* Prevent content overflow */
}

.cross-tab-table th,
.cross-tab-table td {
  padding: 14px; /* Better spacing */
  border: 1px solid #394b5f; /* Border matches theme */
  text-align: center; /* Center text */
  font-size: 10px;
}

.cross-tab-table th {
  background-color: #394b5f; /* Distinct header background */
  color: #ffffff; /* Bright text for headers */
  font-weight: bold; /* Emphasize headers */
  text-transform: uppercase; /* Uniform text style */
}

.cross-tab-table td {
  background-color: #2b3e50; /* Match table body with sidebar */
}

.cross-tab-table tbody tr:hover {
  background-color: #345065; /* Hover effect similar to active state */
  color: #ffffff; /* Brighten text on hover */
}

/* Dropdown styling */
.dropdown-toggle {
  background-color: #394b5f; /* Match sidebar theme */
  color: #ffffff; /* Bright text */
  border: none; /* Clean borderless look */
  border-radius: 5px; /* Rounded corners */
}

.dropdown-toggle:hover {
  background-color: #4a6077; /* Subtle hover effect */
}

/* Buttons */
.btn-primary {
  background-color: #007bff;
  border: none;
  border-radius: 5px;
}

.btn-danger {
  background-color: #d9534f;
  border: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cross-tab-container {
    width: 100%; /* Full width for smaller screens */
    padding: 10px; /* Reduce padding */
  }

  .cross-tab-table th,
  .cross-tab-table td {
    font-size: 12px; /* Adjust font size for smaller screens */
    padding: 8px;
  }
}
